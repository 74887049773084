import React from 'react'
import '../about/about.css'
import culture from '../../assets/img/konsultasi/culture.png'
import document from '../../assets/img/konsultasi/documents.png'
import information from '../../assets/img/konsultasi/information.png'
import majority from '../../assets/img/konsultasi/majority.png' 
import parttime from '../../assets/img/konsultasi/parttime.png' 

const About = () => {
  return (
    <>
        <div className="about" id='about'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12 text-center' style={{color: '#696984'}}>
                    <h1>Bersama Arnantyo Naresyworo</h1>
                    <h4>CEO & Founder Niec Indonesia</h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 d-flex justify-content-center'>
                    <a href='#register'>
                        <div className='btn-konsultasi'>
                            <h2>Konsultasikan Sekarang</h2>
                        </div>
                    </a>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 d-flex justify-content-center text-center' style={{color: '#696984', marginTop: '100px'}}>
                    <h2>Apa yang Kamu Dapat dari Konsultasi Private</h2>
                </div>
            </div>
            <div className='row d-flex justify-content-center' style={{marginTop: '100px'}}>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={information} />
                    <h2>Informasi Yang Akurat</h2>
                    <p>temukan info pendaftaran, persyaratan masuk dan dokumen kuliah di banyak negara</p>
                </div>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={information} />
                    <h2>Jurusan Yang Tepat</h2>
                    <p>Konsultasikan program yang sesuai dengan minat Anda.</p>
                </div>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={culture} />
                    <h2>Pengenalan Budaya Luar</h2>
                    <p>Membantu Anda menyesuaikan diri dengan lingkungan belajar baru dan budaya baru.</p>
                </div>
            </div>
            <div className='row d-flex justify-content-center' style={{marginTop: '100px'}}>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={parttime} />
                    <h2>Biaya dan Part-time Job</h2>
                    <p>Cari info beasiswa dan part-time job dengan gaji hingga ratusan dollar per bulan</p>
                </div>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={document} />
                    <h2>Persiapan Dokumen</h2>
                    <p>Prosedur yang tepat dalam persyaratan dokumen yang bervariasi antar negara.</p>
                </div>
                <div className='col-lg-3 text-center'>
                    <img className='img-fluid-about' src={majority} />
                    <h2>Pengarahan Keberangkatan</h2>
                    <p>Rencanakan keberangkatan Anda dengan lebih baik untuk menghindari masalah yang tidak terduga.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default About