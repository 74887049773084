import React from 'react'
import '../footer/footer.css'
import playStore from '../../assets/img/play-store.png'
import appsStore from '../../assets/img/apps-store.png'

const Footer = () => {
  return (
    <footer style={{marginTop: '150px'}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <div className="footer-wrap">
                    <h4>NIEC INDONESIA</h4>
                    <p>Konsultan Pendidikan dengan pengalaman 14 tahun, telah membantu ribuan anak Indonesia kuliah ke luar negeri</p>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="footer-wrap">
                    <h4>Company</h4>
                    <p><a href="https://www.niecindonesia.com/" target="_blank" rel="noopener noreferrer">About</a></p>
                    <p><a href="">Join Volunteer</a></p>
                    <p><a href="https://superstudent.niecindonesia.com/" target="_blank" rel="noopener noreferrer">Super Student</a></p>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="footer-wrap">
                    <h4>Contact</h4>
                    <p><a href="https://wa.me/6281238431234" target="_blank" rel="noopener noreferrer">0812-3843-1234 (Windri)</a></p>
                    <p><a href="mailto:bizdev@niecindonesia.com">bizdev@niecindonesia.com</a></p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="social-media">
                        <a href="https://www.instagram.com/niec_indonesia/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://www.tiktok.com/@niec_indonesia?lang=id-ID" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-tiktok"></i></a>
                        <a href="https://www.youtube.com/@niec_indonesia" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube"></i></a>
                    </div>
                    <div className='row d-flex justify-content-center mt-3 ml-3'>
                        <div className='col-lg-4'>
                            <a href='https://play.google.com/store/apps/details?id=io.niec.superstudent'><img src={playStore} className='img-fluid' /></a>
                        </div> 
                        <div className='col-lg-4'>
                            <a href='https://apps.apple.com/us/app/super-student/id1614354792'><img src={appsStore} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p className='footer-bottom'>Allrights reserved@niecindonesia.com</p>
    </footer>
  )
}

export default Footer