import React, { Component } from 'react';
import QRCode from "react-qr-code";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API_URL from '../../Api/index'
import Footer from '../../components/footer/Footer';
import Multiselect from 'multiselect-react-dropdown';
import Swal from "sweetalert2";
import { Link, useParams } from 'react-router-dom';
import '../../assets/css/style.css';
import moment from 'moment';

const withRouterParams = (Component) => {
    return(props) => {
        const params = useParams()
        return <Component {...params} params={params} />;
    }
}

class Ticket extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false,
            height:window.innerHeight,
            registrasi_data:[],
            event_data:[],
            event_lokasi:[],
            modal: false,
            modalCheckin: false,
            errors: [],
            provinsi: [],
            jenjang: [],
            kabupaten: [],
            kelas: [],
            sekolah: [],
            kampus: [],
            name: "",
            id_event: 76,
            propinsi_id: "",
            kabupaten_id: "",
            code_kelas: "",
            code_jenjang: "",
            code_sekolah: "",
            attandance: "",
            date_event: ""
        }
        this.setFullScreen.bind(this);
    }

    componentDidMount(){
        this.getDataRegisterEvent();
        this.setFullScreen();
        window.addEventListener("resize", this.setFullScreen);
        this.loadJenjang();
        this.loadProvince();
    }

    componentWillUnmount(){
        window.addEventListener("resize", this.setFullScreen);
    }

    setFullScreen = () => {
        var topSectionEl = document.getElementById('home-fullscreen');
        if(topSectionEl.clientHeight>0) {
            var windowHeight = window.innerHeight;
            this.setState({ 
                height: windowHeight,
                width:window.innerWidth
            });
        }
    }

    getDataRegisterEvent = () => {
        API_URL.eventUrl.get('ticket/'+API_URL.id_event+'/'+this.props.id)
        .then(response => response.data)
        .then(result => {
            this.setState({
                event_data: result.detail,
                event_lokasi: result.lokasi,
                registrasi_data: result.detail.register[0],
                name: result.name.toUpperCase(),
                keterangan: result.jadwal,
                attandance: result.detail.register[0].pivot.attandance,
                date_event: result.date_event
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadProvince = async () => {
        await API_URL.baseUrl.get('master/provinces')
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                provinsi: result
            })
        })
        .catch(error => console.log(error))
    }

    loadJenjang = async () => {
        await API_URL.baseUrl.get('master/jenjang')
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                jenjang: result
            })
        })
        .catch(error => console.log(error))
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangePropinsi = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.handleKabupaten(e.target.value)
            this.handleSekolah(e.target.value)
        })
    }

    handleKabupaten = async (e) => {
        await API_URL.baseUrl.get('master/kabupaten/'+e)
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                kabupaten: result
            })
        })
        .catch(error => console.log(error))
    }

    handleChangeJenjang = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.handleKelas(e.target.value)
        })
    }

    handleKelas = async (e) => {
        await API_URL.baseUrl.get('master/kelas/'+e)
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                kelas: result
            })
        })
        .catch(error => console.log(error))
    }

    handleSekolah = async (e) => {
        await API_URL.baseUrl.get('sekolah-indonesia/'+e)
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                sekolah: result
            })
        })
        .catch(error => console.log(error))
    }

    setPilihanSekolah = (e) => {
        this.setState({
            code_sekolah: e[0].kode_sekolah
        })
    }

    checkin() {
        this.setState({ 
            modalCheckin: true
        })
    }

    saveRow() {
        let formData = new FormData()

        formData.append('code_registrasi', this.state.event_data.register[0].register_code)
        formData.append('id_event', this.state.id_event)
        // formData.append('propinsi_id', this.state.propinsi_id)
        // formData.append('kabupaten_id',this.state.kabupaten_id)
        // formData.append('code_jenjang', this.state.code_jenjang)
        // formData.append('code_sekolah', this.state.code_sekolah)

        API_URL.eventUrl.post('world-education-festival/checkin', formData)
        .then(response => {
            Swal.fire({
                title: 'Sukses',
                icon: 'success',
                type: 'success',
                confirmButtonColor: '#3085d6',
                text: 'Selamat Anda Sudah Berhasil Checkin',
            });
            this.setState({
                modalCheckin: false
            })
        })
        .catch(error => console.log(error))
    }

    renderDialog() {
        const { modal } = this.state;

        return (
            <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                <ModalHeader toggle={() => this.setState({ modal: false })} style={{color: 'black'}}>Update Data</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="" style={{color: 'black'}}>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Propinsi</label>
                                <select className="form-control" name="propinsi_id" type="text" id="propinsi_id" onChange={(e) => this.handleChangePropinsi(e)}>
                                    <option>--Pilih Provinsi--</option>
                                    {
                                        this.state.provinsi.map(data => {
                                            return <option key={data.id} value={data.id}>{data.province_name}</option>
                                        })
                                    }
                                </select>
                                <span>{this.state.errors.propinsi_id}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Kabupaten</label>
                                <select className="form-control" name="kabupaten_id" type="text" id="kabupaten_id" onChange={(e) => this.handleChange(e)}>
                                    <option>--Pilih Kabupaten--</option>
                                    {
                                        this.state.kabupaten.map(data => {
                                            return <option key={data.id} value={data.id}>{data.kabupaten_name}</option>
                                        })
                                    }
                                </select>
                                <span>{this.state.errors.kabupaten_id}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Jenjang Pendidikan</label>
                                <select className="form-control" name="code_jenjang" type="text" id="code_jenjang" onChange={(e) => this.handleChangeJenjang(e)}>
                                    <option>--Pilih Jenjang Pendidikan</option>
                                    {
                                        this.state.jenjang.map(data => {
                                            return <option key={data.code_jenjang} value={data.code_jenjang}>{data.jenjang_pendidikan}</option>
                                        })
                                    }
                                </select>
                                <span>{this.state.errors.code_jenjang}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Kelas</label>
                                <select className="form-control" name="code_kelas" type="text" id="code_kelas" onChange={(e) => this.handleChange(e)}>
                                    <option>--Pilih Kelas--</option>
                                    {
                                        this.state.kelas.map(data => {
                                            return <option key={data.code} value={data.code}>{data.name}</option>
                                        })
                                    }
                                </select>
                                <span>{this.state.errors.code_kelas}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Sekolah</label>
                                <Multiselect
                                    onSelect={(e) => this.setPilihanSekolah(e)}
                                    onRemove={(e) => this.setPilihanSekolah(e)}
                                    options={this.state.sekolah}
                                    selectionLimit={1}
                                    emptyRecordMsg="data tidak ditemukan, kosongkan pilihan."
                                    displayValue='nama_sekolah'
                                    placeholder="Sekolah / Kampus Asal"
                                />
                                <span>{this.state.errors.kelas}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Check In Now</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderDialogCheckin() {
        const { modalCheckin } = this.state;

        return (
            <Modal isOpen={modalCheckin} toggle={() => this.setState({ modalCheckin: false })}>
                <ModalHeader toggle={() => this.setState({ modalCheckin: false })} style={{color: 'black'}}>Checkin</ModalHeader>
                <ModalBody>
                    Apakah Anda Ingin Checkin Sekarang
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Check In Now</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalCheckin: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        var date = new Date()
        let today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() +' '+ date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()

        return (
            <div>
                <section className="bg-header-banner">
                    <Container>
                        <div className="home-fullscreen" id="home-fullscreen" style={{height : this.state.height+"px"}}>
                            <div className="full-screen">
                                <div className="home-wrapper home-wrapper-alt">
                                    <Row>
                                        <Col sm='12'>
                                            <h1 className='font-helloween'>E-TICKET</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <div className="font-helloween text-center mt-5">
                                                <h2>{this.state.event_lokasi.place}, {moment(this.state.event_lokasi.time_event).format('DD MMMM YYYY')}</h2>
                                            </div>
                                            <div className='font-helloween text-center'>
                                                <h1>{this.state.registrasi_data.register_code}</h1>
                                                <h1>{this.state.name}</h1>
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="font-helloween text-center">
                                                <div style={{backgroundColor: 'white'}}>
                                                {
                                                    this.state.registrasi_data.register_code != undefined ?
                                                    <QRCode className='m-3' value={this.state.registrasi_data.register_code} />
                                                    :
                                                    ''
                                                }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center mt-5'>
                                        {
                                            this.state.attandance != 2 ?
                                                <Col sm='6' style={{textAlign:'center',paddingTop:'12px'}}>
                                                    <button className="btn btn-custom font-helloween-h2" onClick={() => this.checkin()}>Checkin</button>
                                                </Col>
                                            :
                                            ''
                                        }
                                        {/* {
                                            this.state.attandance == 2 ?
                                            <Col sm='6' style={{textAlign:'center',paddingTop:'12px'}}>
                                                <a href={API_URL.superStudent+'/load-pdf?name='+this.state.registrasi_data.name} target='_blank'><button className="btn btn-custom font-helloween-h2">Download Certificate</button></a>
                                            </Col>
                                            :
                                            ''
                                        } */}
                                        {
                                            this.state.attandance == 2 &&  Date.parse(this.state.date_event) > Date.parse(today) ?
                                            <Col sm='6' style={{textAlign:'center',paddingTop:'12px'}}>
                                                <Row>
                                                    <Col lg='6' md='6' xs='6'>
                                                        <Link className="btn btn-custom font-helloween-h2" style={{color:'white'}} to={'/pilihkampus/'+this.state.registrasi_data.pivot.id+'/'+this.state.registrasi_data.register_code}><a style={{color: 'white'}}>Pilih Kampus</a></Link>
                                                    </Col>
                                                    <Col lg='6' md='6' xs='6'>
                                                        <Link className="btn btn-custom font-helloween-h2" style={{color:'white', marginRight: "auto", marginLeft: "auto", textAlign: 'center'}} to={'/list-pilihan/'+this.state.registrasi_data.pivot.id+'/'+this.state.registrasi_data.register_code}><a style={{color: 'white'}}>List Pilihan</a></Link>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            :
                                            ''
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                {this.renderDialog()}
                {this.renderDialogCheckin()}
                <Footer/>
            </div>
        )
    }
}

export default withRouterParams(Ticket);