import React, { Component } from 'react';
import { Button, Container, Row, Col, FormGroup, Label } from "reactstrap";
import API_URL from '../../Api/index'
import image from "../../assets/img/level3/img2.png";
import Footer from '../../components/footer/Footer';
import Swal from 'sweetalert2';

class Ambassador extends Component{

    constructor(props) {
        super(props);
        this.state = {
            nama_lengkap: "",
            email: "",
            alamat: "",
            domisili: "",
            no_wa: "",
            no_ktp: "",
            no_rek: "",
            akun_ig: "",
            akun_sosmed: "",
            message: "Error !",
            message_feedback: "",
            errors: [],
            showSwal: false,
            formAlert: { display: 'none' },
            formAlertClassName: 'alert alert-danger',
            inputBorder: "border: 1px solid #ca0b0b",
            btnDisabled: false,
            aktifKonten: [],
        }
    }

    componentDidMount() {
        
    }

    handleInput = (e) => {
        this.setState({
          [e.target.name]:e.target.value
        })
    }
    
    validateNamaLengkap = () => {
        const nama_lengkap = this.state.nama_lengkap;
        if(nama_lengkap === "" || nama_lengkap === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.nama_lengkap = 'Nama Lengkap Harus Diisi Harus Diisi'; 
                errors.nama_lengkapStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.nama_lengkap = '';
                errors.nama_lengkapStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateEmail = () => {
        const email = this.state.email;
        if(email === "" || email === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.email = 'Nama Lengkap Harus Diisi Harus Diisi'; 
                errors.emailStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.email = '';
                errors.emailStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateAlamat = () => {
        const alamat = this.state.alamat;
        if(alamat === "" || alamat === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.alamat = 'Alamat Harus Diisi'; 
                errors.alamatStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.alamat = '';
                errors.alamatStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateDomisili = () => {
        const domisili = this.state.domisili;
        if(domisili === "" || domisili === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.domisili = 'Domisili Harus Diisi'; 
                errors.domisiliStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.domisili = '';
                errors.domisiliStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateNoWa = () => {
        const no_wa = this.state.no_wa;
        if(no_wa === "" || no_wa === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_wa = 'No. Whatsapp Harus Diisi'; 
                errors.no_waStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_wa = '';
                errors.no_waStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateNoKtp = () => {
        const no_ktp = this.state.no_ktp;
        if(no_ktp === "" || no_ktp === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_ktp = 'No. KTP Harus Diisi'; 
                errors.no_ktpStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_ktp = '';
                errors.no_ktpStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateNoRek = () => {
        const no_rek = this.state.no_rek;
        if(no_rek === "" || no_rek === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_rek = 'No. Rekening dan Nama Pemilik Rekening Harus Diisi'; 
                errors.no_rekStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_rek = '';
                errors.no_rekStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateAkunIg = () => {
        const akun_ig = this.state.akun_ig;
        if(akun_ig === "" || akun_ig === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.akun_ig = 'Akun Instagram Harus Diisi'; 
                errors.akun_igStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.akun_ig = '';
                errors.akun_igStatus = true;                             
                return { errors };
            })
        }
    }
    
    validateAkunSosmed = () => {
        const akun_sosmed = this.state.akun_sosmed;
        if(akun_sosmed === "" || akun_sosmed === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.akun_sosmed = 'Akun Instagram Harus Diisi'; 
                errors.akun_sosmedStatus = false;
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.akun_sosmed = '';
                errors.akun_sosmedStatus = true;                             
                return { errors };
            })
        }
    }
    
    handleValidation() {
        let formIsValid = true;
        if(!this.state.errors.nama_lengkapStatus){
            formIsValid = false;
        }
        if(!this.state.errors.emailStatus){
            formIsValid = false;
        }
        if(!this.state.errors.alamatStatus){
            formIsValid = false;
        }
        if(!this.state.errors.domisiliStatus){
            formIsValid = false;
        }
        if(!this.state.errors.no_waStatus){
            formIsValid = false;
        }
        if(!this.state.errors.no_ktpStatus){
            formIsValid = false;
        }
        if(!this.state.errors.no_rekStatus){
            formIsValid = false;
        }
        if(!this.state.errors.akun_igStatus){
            formIsValid = false;
        }
        if(!this.state.errors.akun_sosmedStatus){
            formIsValid = false;
        }
        return formIsValid;
    }
    
    saveData(){
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateNamaLengkap();
            this.validateEmail();
            this.validateAlamat();
            this.validateDomisili();
            this.validateNoWa();
            this.validateNoKtp();
            this.validateNoRek();
            this.validateAkunIg();
            this.validateAkunSosmed();
        }
    }
    
    sendData() {
        var formData = new FormData();
        formData.append('id_event', 5);
        formData.append('nama_lengkap', this.state.nama_lengkap);
        formData.append('email', this.state.email);
        formData.append('alamat', this.state.alamat);
        formData.append('domisili', this.state.domisili);
        formData.append('no_wa', this.state.no_wa);
        formData.append('no_ktp', this.state.no_ktp);
        formData.append('no_rek', this.state.no_rek);
        formData.append('akun_ig', this.state.akun_ig);
        formData.append('akun_sosmed', this.state.akun_sosmed);

        API_URL.eventUrl.post('world-education-festival/ambassador/register', formData)
        .then(response => {
            Swal.fire({
                icon: 'success',
                html:
                    'Selamat Anda Sudah Terdaftar Sebagai'+ '<br/>' +
                    'Ambassador World Education Festival 2022'+ '<br/>' +
                    'Berikut Adalah Kode Referal Anda'+ '<br/>' +
                    '<h2>'+response.data.data.kode_expo_ambassador+'</h2>'
            }).then((result) => {
                window.location.reload()
            })
        })
        .catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                Swal.fire({
                    icon: 'error',
                    html:
                        'Maaf Ada Kesalahan' + '<br/>' +
                        err.response.data.data.nama_lengkap + '<br/>' +
                        err.response.data.data.email + '<br/>' +
                        err.response.data.data.no_wa + '<br/>' +
                        err.response.data.data.no_ktp + '<br/>' +
                        err.response.data.data.no_rek + '<br/>'
                })
            }
        })
    }

    render(){
        return(
            <div className='bg-header-banner'>
                <section id="home">
                    <Container style={{color: 'white'}}>
                        <div className="home-fullscreen" id="home-fullscreen" style={{height : this.state.height+"px"}}>
                            <div className="full-screen">
                                <div className="home-wrapper home-wrapper-alt">
                                    <Row className='justify-content-md-center mb-5'>
                                        <Col md="12">
                                            <img style={{display: 'block', width:'100%',maxWidth:'600px',height:'100%',paddingTop:'50px', marginLeft: 'auto', marginRight: 'auto'}} src={image} alt="logo"/>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col md='12' style={{textAlign:'center'}}>
                                            <h1>Welcome To The Biggest International Event<br/> by NIEC Indonesia</h1>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section>
                    <Container style={{color: 'white'}}>
                        <div className="row">
                            <div className="col-lg-12" style={{textAlign: 'center'}}>
                            <h2>Jadilah Ambassador World Education Festival (WEF) dan dapatkan bonus menariknya!</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" style={{textAlign: 'left', fontSize: 'large'}}>
                            <h3>Syarat & Ketentuan :</h3>
                            <ol>
                                <li>Minimal umur 18th untuk menjadi Ambassador.</li>
                                <li>Kriteria peserta WEF yang dicari adalah usia 17 - 25th dan berminat kuliah ke luar negeri</li>
                                <li>Ambassador akan mendapatkan kode refferal untuk diberikan ke peserta ketika mendaftar WORLD EDUCATION FESTIVAL.</li>
                                <li>Minimal mencari 10 peserta untuk bisa mendapatkan bonus dan maksimal 25 orang.</li>
                                <li>Bonus bisa dicairkan jika pesertanya "daftar & hadir" di WORLD EDUCATION FESTIVAL sebesar IDR 10k / peserta.</li>
                                <li>Bonus akan dikirimkan melalui rekening yang sudah dicantumkan setelah WORLD EDUCATION FESTIVAL selesai.</li>
                                <li>Materi poster bisa di download di <span><a href="https://drive.google.com/drive/folders/1vAzo12QWhiXAQ900mALXsAqv5U_4M7-w?usp=sharing" target="_blank" rel="noopener noreferrer">[sini]</a></span></li>
                            </ol>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className='mt-5'>
                    <Container style={{color: 'white'}}>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                            <h1 className="section-title text-center" style={{ fontWeight: "500" }}>REGISTER WEF AMBASSADOR</h1>
                            <div className="section-title-border margin-t-20"></div>
                            </div>
                        </div>
                        <form className="needs-validation" noValidate="">
                            <Row style={{ marginTop: "40px", justifyContent: "center" }}>
                                <Col lg="6" md="6" sm="6" style={{ backgroundColor: "" }}>
                                    <div style={{ backgroundColor: '', paddingTop: "0px" }}>
                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="nama_lengkap" style={{ color: 'white', fontWeight: '1000' }}>Nama Lengkap</Label>
                                        <input md="3" className="form-control"
                                            name="nama_lengkap"
                                            type="nama_lengkap" 
                                            id="nama_lengkap"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateNamaLengkap}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.nama_lengkap}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="email" style={{ color: 'white', fontWeight: '1000' }}>Email</Label>
                                        <input md="3" className="form-control"
                                            name="email"
                                            type="email" 
                                            id="email"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateEmail}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.email}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="alamat" style={{ color: 'white', fontWeight: '1000' }}>Alamat</Label>
                                        <input md="3" className="form-control"
                                            name="alamat"
                                            type="alamat" 
                                            id="alamat"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateAlamat}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.alamat}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="domisili" style={{ color: 'white', fontWeight: '1000' }}>Domisili</Label>
                                        <input md="3" className="form-control"
                                            name="domisili" 
                                            type="domisili" 
                                            id="domisili"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateDomisili}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.domisili}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="no_wa" style={{ color: 'white', fontWeight: '1000' }}>No. Whatsapp</Label>
                                        <input md="3" className="form-control"
                                            name="no_wa"
                                            type="no_wa" 
                                            id="no_wa"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateNoWa}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.no_wa}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="no_ktp" style={{ color: 'white', fontWeight: '1000' }}>No. KTP</Label>
                                        <input md="3" className="form-control"
                                            name="no_ktp"
                                            type="no_ktp" 
                                            id="no_ktp"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateNoKtp}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.no_ktp}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="no_rek" style={{ color: 'white', fontWeight: '1000' }}>No. Rekening & Nama Bank</Label>
                                        <input md="3" className="form-control"
                                            name="no_rek"
                                            placeholder="contoh (BCA/1234567890/Adrian)"
                                            type="no_rek" 
                                            id="no_rek"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateNoRek}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.no_rek}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3" style={{ marginBottom: "0px" }}>
                                        <Label for="akun_ig" style={{ color: 'white', fontWeight: '1000' }}>Akun Instagram</Label>
                                        <input md="3" className="form-control"
                                            name="akun_ig" 
                                            type="akun_ig" 
                                            id="akun_ig"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateAkunIg}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.akun_ig}</span>
                                        </label>
                                    </FormGroup>

                                    <FormGroup className="mb-3"style={{ marginBottom: "0px" }}>
                                        <Label for="akun_sosmed" style={{ color: 'white', fontWeight: '1000' }}>Akun Media Sosial Lainnya</Label>
                                        <input md="3" className="form-control"
                                            name="akun_sosmed" 
                                            type="akun_sosmed" 
                                            id="akun_sosmed"
                                            onChange={this.handleInput}
                                            onBlur={(e) => this.validateAkunSosmed}
                                        />
                                        <label className="error-label-register">
                                        <span style={{color: 'red'}}>{this.state.errors.akun_sosmed}</span>
                                        </label>
                                    </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <Row className='mb-5' style={{justifyContent: "center" }}>
                            <Col lg="6" md="6" sm="6" style={{ textAlign: "center" }}>
                                <Button disabled={this.state.btnDisabled} className="text-left btn btn-alert btn-custom" color="success" type="button" onClick={(e) => this.saveData()}>Submit</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer/>
            </div>
        )
    }

}

export default Ambassador