import React, { useEffect, useState } from 'react'
import '../header/header.css'
import BannerPakArnan from '../../assets/img/konsultasi/foto-pak-arnan.png'
import reward from '../../assets/img/konsultasi/Icon-01.png'

const Navbar = () => {
  const [clicked, setClicked] = useState(false)
  const [sticky, setStickyClass] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('active') : setStickyClass('');
    }
  }

  const handleClick = () => {
    setClicked(!clicked)
  }

  return (
    <div className="header" id='header'>
      <div className="banner">
        <div className={`navbar ${sticky ? `active` : ''}`}>
          <div className='nav-menu'>
            <ul className={`nav-list ${clicked ? `active` : ''}`}>
              <li onClick={handleClick}><a href="#header">Home</a></li>
              <li onClick={handleClick}><a href="#about">Why</a></li>
              <li onClick={handleClick}><a href="#register">Gallery</a></li>
              <li onClick={handleClick}><a href="#register">Contact Us</a></li>
            </ul>
          </div>
          <div className="bar-menu" onClick={handleClick}>
            <i className="fa-solid fa-bars-staggered"></i>
          </div>
        </div>
        <div className='card-judul-header row'>
          <div className='col-lg-12 d-flex justify-content-center'>
            <h1 style={{color: '#df1c25'}}>Private Konsultasi</h1>
          </div>
          <div className='col-lg-12 d-flex justify-content-center'>
            <h1 style={{color: '#004870'}}>Kuliah dan Berkarir</h1>
          </div>
          <div className='col-lg-12 d-flex justify-content-center'>
            <h1 style={{color: '#004870'}}>di Luar Negeri</h1>
          </div>
        </div>
        <div className='card-judul-header row'>
          <div className='col-lg-12 d-flex justify-content-center'>
            <img className='banner-award' src={reward} alt="banner" />
          </div>
        </div>
        <div className='card-judul-foto row'>
          <div className='col-lg-12'>
            <img className='banner-pirate' src={BannerPakArnan} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar